import React from 'react';
import Sidebar from './Sidebar';
import Dashboard from './Dashboard';

export default function Admin() {
  return (
    <>
      <Sidebar />
      <Dashboard />
    </>
  )
}